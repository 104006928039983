import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background: #75b09c;
  display: ${props => props.visible ? 'block' : 'none'};
  z-index: 5000;

  @media(min-width: 600px) {
    display: none !important;
  }
`

const WaitlistMobileButton = ({ openWaitlistSignup }) => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const scrollHeight = document.body.scrollHeight || document.documentElement.scrollHeight
      if (scrollTop / scrollHeight > 0.55) {
        setShowButton(true);
      }
    }
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  });

  return (
    <Wrapper
      visible={showButton}
      onClick={openWaitlistSignup}
    >
      Interested... but not ready yet?
    </Wrapper>
  )
}

export default WaitlistMobileButton
