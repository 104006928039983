import React from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import styled from '@emotion/styled'
import CourseBundle from './courseBundle'

const showCheckout = ({ email, name, coupon }) => {
  // eslint-disable-next-line no-undef
  Paddle.Checkout.open({
    product: 592859,
    coupon,
    email,
    passthrough: name,
    allowQuantity: false,
  })
}

const courseBundle = {
  name: 'Learn how professional teams work while building a real-world project',
  description: 'You will learn and experience how real developer teams operate. You will work with designs, tasks, and GitHub workflows.<br /><br /><b>This course includes:</b>',
  price: 99,
  features: [
    'Introductory course material and tutorials',
    'Professional designs on Zeplin',
    'Ready-made tasks to implement the project',
    'GitHub repo prepared for you',
    'Automated code reviews with valuable tips for every task',
    'Professional example implementations for every feature',
    'Access to Slack community',
    '<b>30-day money-back guarantee</b>',
  ]
}

const Wrapper = styled.section`
`

function Pricing() {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const discountedPrice = queryParams.discountedPrice

  return (
    <Wrapper>
      <CourseBundle
        {...courseBundle}
        discountedPrice={queryParams.price}
        onClick={() => showCheckout(queryParams)}
      />
    </Wrapper>
  )
}

export default Pricing