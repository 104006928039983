import React, { useState } from 'react';
import styled from '@emotion/styled';
import Button from '../buttons/button';

const Container = styled.div`
  width: 100%;
  margin: 80px -20px;
  padding: 40px 20px;
  box-shadow: 2px 2px 6px 0 rgba(117, 176, 156, 0.3);
  background: #e5f0ed;
  transform: skew(0deg, -2deg);
`

const InnerContainer = styled.div`
  transform: skew(0deg, 2deg);
  font-size: 18px;
  padding: 30px;
`

const Name = styled.h2`
  margin: 20px 0 40px;
`

const FeatureList = styled.ul`

`

const FeatureItem = styled.li`

`

const Description = styled.div`

`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`

const AvailableSeats = styled.div`
  margin: 10px 0 0;
  text-align: center;
  font-weight: 600;
`

function CourseBundle({ id, name, price, discountedPrice, description, features, availableSeats, maxSeats, onClick }) {
  const buttonText = discountedPrice
    ? `Enroll now for $${discountedPrice} instead of $${price}`
    : `Enroll now for $${price}`

  return (
    <Container>
      <InnerContainer>
        <Name dangerouslySetInnerHTML={{ __html: name}} />
        <Description dangerouslySetInnerHTML={{ __html: description }}/>
        <FeatureList>
          {
            features.map((feature) => (
              <FeatureItem dangerouslySetInnerHTML={{ __html: feature }} />
            ))
          }
        </FeatureList>
        <StyledButton onClick={() => onClick(id)}>
          {buttonText}
        </StyledButton>
        {
          maxSeats && (
            <AvailableSeats>
              {availableSeats} / {maxSeats} available seats are left
            </AvailableSeats>
          )
        }
      </InnerContainer>
    </Container>
  );
}

export default CourseBundle