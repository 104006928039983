import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "../components/container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MailerliteModal from "../components/MailerliteModal"
import WaitlistMobileButton from "../components/waitlistMobileButton"
import Pricing from '../components/pricing/pricing'
import Footer from "../components/footer"
import Testimonial from "../components/testimonial"
import InstructorImage from "../components/instructorImage"

const Headline = styled.h1`
  margin-bottom: 26px;

  @media(max-width: 500px) {
    font-size: 45px;
    line-height: 54px;
    margin-top: 50px;
  }
`

const Subline = styled.h2`
  font-size: 36px;
  line-height: 44px;
  margin: 0 0 72px;

  @media(max-width: 500px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 40px;
  }
`

const Image = styled(Img)`
  margin: 60px -60px;
  width: calc(100% + 120px);

  @media(max-width: 630px) {
    width: calc(100% + 40px);
    margin: 60px -20px;
  }
`

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      jobListing: file(relativePath: { eq: "job-listing.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      martinKrugerTweet: file(relativePath: { eq: "martin-kruger-tweet.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kanban: file(relativePath: { eq: "kanban.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designs: file(relativePath: { eq: "designs.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pr: file(relativePath: { eq: "automated-pr.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tests: file(relativePath: { eq: "automated-tests.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eslint: file(relativePath: { eq: "eslint.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      portrait: file(relativePath: { eq: "johannes-kettmann.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const params = new URLSearchParams(props.location.search)
  const isCheckout = params.get('checkout')

  const [showWaitlistSignup, setShowWaitlistSignup] = useState(false)
  const [openedAt] = useState(Date.now())

  const openWaitlistSignup = () => setShowWaitlistSignup(true);

  const onMouseLeavePage = () => {
    const atLeastTenSecondsPassed = Date.now() - openedAt > 10000
    const closedBefore = localStorage.getItem('closed-waitlist-signup')
    const show = !isCheckout && atLeastTenSecondsPassed && !closedBefore
    setShowWaitlistSignup(show)
  }

  const onWaitlistSignupClose = () => {
    localStorage.setItem('closed-waitlist-signup', true)
    setShowWaitlistSignup(false)
  }

  return (
    <>
      <MailerliteModal
        visible={showWaitlistSignup}
        onClose={onWaitlistSignupClose}
      />
      <WaitlistMobileButton
        openWaitlistSignup={openWaitlistSignup}
      />

      <Layout onMouseLeave={onMouseLeavePage}>
        <SEO
          title={`"Am I ready for my first React job in a professional team?"`}
          image="https://jkettmann-social-card.netlify.app/.netlify/functions/create-card?title=Am%20I%20ready%20for%20my%20first%20React%20job%20in%20a%20professional%20team?&description=Pro%20Developer%20Courses&website=ooloo.io"
        />

        <Container>
          <Headline>
            I spent all this time learning React but can't even get an interview!
          </Headline>
          <Subline>
            Am I ready for my first React job in a professional team?
          </Subline>
          <p>
            No matter if you're a CS or bootcamp grad or if you try to take the leap from amateur to
            professional as a self-taught developer: For most of us, our career as React developer
            starts with tutorials. <b>Lots of tutorials.</b>
          </p>
          <p>
            Unfortunately, it's hard to find quality content. The tutorials are confusing. The instructors
            don't seem to have a clue themselves. And of course, <b>the code is outdated as hell
            still using React version 15 and good old class components</b>. Did you hear of hooks already?
          </p>
          <p>
            So you invest a little money. You follow a Udemy course or two that promise you'll become a Senior React
            dev in 40hrs for $12.99 on "discount"! <span role="img" aria-label="rocket">🚀
            You buy courses by Stephen Grider, Wes Bos, ... you name them.</span>
          </p>
          <p>
            <b>And yet you're stuck in tutorial hell!</b> You follow instructors and code along. But that's not the real
            deal, right? How can you escape from this trap?
          </p>
          <h2>
            Everyone on the internet says: "Build something"
          </h2>
          <p>
            You need to get your feet wet without someone holding your hand. You know you need to practice your coding
            skills on your own project.
          </p>
          <p>
            Unfortunately, "just build something" is easier said than done. <b>You have no idea what to build.</b> You stare at a
            blank screen. The blinking cursor seems to make fun of you. Again, how many projects do you need before you can
            even think of applying with your portfolio?
          </p>
          <p>
            In fact, you know there's lots of competition. You're not the only one who wants to start a career as a
            React developer. You feel the pressure. <b>You need an outstanding project to be considered for a job.</b>
          </p>
          <p>
            But yet you end up doing what everybody does: You build a half-baked Todo app and maybe yet another
            YouToob. <span role="img" aria-label="face-with-raised-eyebrow">🤨</span>
          </p>
          <p>
            At least you feel somewhat comfortable with HTML, CSS, and JavaScript by now. Perhaps even React.
          </p>

          <h2>But is that enough to get you a job?</h2>
          <p>
            You already have this nagging feeling that the coding skills are not everything that's required to call yourself a
            professional developer.
          </p>
          <p>You may be right. Once you start looking for jobs you typically see listings like this for a frontend web developer:</p>
          <Image fluid={data.jobListing.childImageSharp.fluid} />
          <p>
            Holy moly, that's a lot of stuff! Let's be honest: you can check off the top two. At least if
            you leave out the "proficient"... But what about the rest?
          </p>

          <h2>How are you supposed to have the skills of a professional without a prior job?</h2>
          <p>Catch-22, right? All those tutorials and courses focus on one thing: writing code.</p>
          <p>But what about</p>
          <ul>
            <li>Git, pull requests, and code reviews?</li>
            <li>working with real pixel-perfect designs?</li>
            <li>thoroughly testing an application?</li>
            <li>agile team workflows?</li>
            <li>working in the scope of tickets in a project management system?</li>
          </ul>
          <p>There seems to be so much for you to learn. At the same time, you need to find a job soon...</p>

          <h2>You realize it's not that easy to get your foot in the door</h2>
          <p>
            But once you start applying you might really struggle to get an interview.
            Or worse: Maybe you don't even get a reply. You spent all this time striving to become a
            professional React dev and yet <b>you're not even worth a reply to your application!?</b>
          </p>
          <p>
            So what is your problem? <b>Aren't you good enough?</b> Maybe you're just not job-ready yet.
          </p>
          <p>
            You know it's possible though. There seem to be success stories of developers landing their first job
            all over the internet. What does it take to get <u>your</u> foot into the door?
          </p>

          <h2>
            What if you knew how to work like a professional React developer <u>before</u> starting your first job?
          </h2>
          <p>
            And what if you had the perfect project idea for your portfolio on top of that?
          </p>
          <p>
            A project that has just the right complexity to <b>show all the skills an employer wants to see</b> but
            is quick to build. A project that is challenging... but in a way that is fun and helps you improve your skills.
          </p>
          <p>
            What if you had not only the idea but <b>up-to-date resources that teach you the skills required to implement it?</b>
          </p>
          <p>
            Skills that professional software developers have besides their programming knowledge.
            <b>The evergreen skills that you can use in any job.</b> No matter if you work as an employee in a startup/enterprise/agency
            or if you decide to become a freelancer.
          </p>
          <p>
            With a project idea and resources like that, you would have <b>a clear path to follow</b> from setting up the project
            until writing the last line of code.
          </p>
          <p>
            For sure, you would improve your coding. But not only that. You'd have lots of opportunities to practice your new skills
            hands-on as well. You could let them really sink in.
          </p>

          <h2>You would be ready for the job!</h2>
          <p>
            Once you had a project like that you would be ready to start applying for jobs: <b>you'd have the skills, you'd have the portfolio,
            you'd be ready for the job.</b>
          </p>
          <p>
            Your portfolio would shine and <b>stand out from other applications</b>. It would be raining replies and you could fill
            your calendar with job interviews.
          </p>
          <p>
            Hell, maybe you could even skip the entry-level and jump straight to a normal developer position.
          </p>
          <p>
            It won't be easy though. Nobody just turns into a dream-candidate overnight. But with the right
            insights, you could get an important head start over other junior developers competing for the job.
          </p>

          <h2>Implement a real-world project like you would in a professional team.</h2>

          <InstructorImage />

          <p>
            Hi, I'm Johannes Kettmann, the creator of this course. I'm a self-taught React developer myself and know the struggle
            of finding your first job and breaking into the industry first-hand.
          </p>
          <p>
            But let me tell you: now with 10+ years of experience as a software developer working in agencies, startups, and enterprises
            life got a lot easier. I can pick the job I like from multiple offers. I'm in a comfortable situation where I can
            work only half the year and still make six figures.
          </p>
          <p>
            Once I started working in professional teams I realized that coding is only part of the equation.
          </p>
          <p>
            <b>It's your coding skills <u>plus</u> these other skills that make you a valuable job candidate.</b>
          </p>
          <p>
            <b>Unfortunately, you usually have to learn these other skills the hard way</b>: on the job
            when you have to get used to an unknown code base, new team members,
            company structures... This is not easy and can mean a lot of stress.
          </p>
          <p>
            That's why this course exists.
          </p>
          <p>
            The goal is to help you level up your React skills and become
            a better software developer with professional skills that you usually learn only while working on
            real teams.
          </p>
          <p>
            <b>With these skills, you can stand out from the crowd of job applicants.</b>
          </p>
          <p>
            I believe that deep learning can only be achieved with practice. The approach this course takes
            is thus very hands-on.
          </p>

          <Testimonial
            name="Martin Kruger"
            role="Self-taught React developer"
            image="/images/avatar-martin.jpg"
            text={
              <>
                <b>Got the job</b> and they're starting me on Monday! <b>The pixel-perfect design technique I learnt here blew them away!</b><br /><br />
                I've struggled to find a job for a long time. I can say with confidence that this course is what led me to landing the job.<br /><br />
                It's probably the greatest thing to have happened to me when it comes to my web dev education... and I've done a lot of courses.
              </>
            }
          />

          <h2>This course is not like a typical tutorial or Udemy video course</h2>
          <p>
            Once you enroll, you will get access to a set of tools that professional teams in real
            companies use to create their products, from small start-ups to big enterprises.
          </p>
          <p>
            The course mimics the environment in which experienced software development teams work:
          </p>
          <p>
          <b>You will get access to designs made by a professional UI-designer. You will be
            invited to a project management tool that contains a list of tasks describing the functionality
            of the application.</b>
          </p>

          <Image fluid={data.martinKrugerTweet.childImageSharp.fluid} />

          <p>
            Then it's your turn. This course is not like a typical tutorial or Udemy video course. You don't
            follow an instructor coding before you. <b>It's <u>you</u> who takes the tasks and designs and
            implements the features accordingly.</b> Just as you would in a real job.
          </p>
          <p>
            Once you're done with a task you send the code to GitHub and open a pull request. (Don't know what that is? Don't worry 🙂)
          </p>
          <p>
            You will get an <b>automated review</b> that contains lots of tips and advice for the specific task.
            You will also get <b>access to an example implementation</b> so that you can compare your own code or find help
            in case you're stuck. No worries, the example code is up-to-date using modern React.
          </p>
          <p>
            <i>Optionally, you can get code reviews for every task or the complete project if you buy one of the premium packages.</i>
          </p>
          <p>
            This is how the course works in a nutshell. <b>You can work at your own pace</b> to fit this course into your schedule.
            I have a small daughter myself so I know time can be scarce when you have a full-time job and a family.
          </p>

          <Testimonial
            name="Erin Stuelke"
            role="Python developer / data scientist"
            image="/images/avatar-erin.jpg"
            linkedin="https://www.linkedin.com/in/erin-stuelke-660b29174/"
            text={
              <>
                <b>Honestly, this was better than most programming courses I took in university.</b> It is really well organized and designed. I liked the level of feedback and the <b>feeling of being in a real team setting</b>.<br /><br />
                The course definitely helped me <b>improve my day-to-day Git workflow.</b> I learned a lot of little (and big!) things about React.<br /><br />
                It seemed you were genuinely interested in seeing me succeed.
              </>
            }
          />

          <h2>What you will learn in this course</h2>
          <p>
            The most important part is the opportunity to experience and practice professional skills hands-on.
            But this doesn't mean you need to know everything upfront.
          </p>
          <p>
            <b>You will get introductory resources with detailed explanations and small tutorials about topics like</b>
          </p>
          <ul>
            <li>creating pixel-perfect layouts according to a design</li>
            <li>how to work with Git and GitHub in a team environment</li>
            <li>working with Kanban as Agile workflow</li>
            <li>splitting an application into small tasks</li>
            <li>implementing an app according to these tasks</li>
            <li>writing automated integration tests</li>
          </ul>
          <p>
            These are all valuable skills that even some professional developers struggle with.
          </p>

          <Testimonial
            name="Sergei Guk"
            role="Laravel backend developer"
            image="/images/avatar-sergei.jpg"
            linkedin="https://www.linkedin.com/in/sergei-guk-239a7a59/"
            text={
              <>
                This course is a great way to break from a tutorial hell into <b>developing projects completely on your own</b>.<br /><br />
                <b>Thorough code reviews</b> from an experienced instructor and <b>real-world workflow</b> (using git, group chat, project management, and design apps) help you boost your React knowledge as you are developing this nice little project completely from scratch making all architectural decisions.<br /><br />
                Johannes is an awesome instructor and overall I highly recommend his course!
              </>
            }
          />

          <p>
            <b>This course <u>won't</u> teach you React from scratch though.</b> It's not a good fit for a complete beginner.
            If you're in doubt whether or not your skills or available time are enough have a look at the FAQ section below.
          </p>
          <p>
            If you read until here simply pick one of the three bundles below 🙂
          </p>

          <Pricing />

          <Testimonial
            name="Ayoub Idelkanoun"
            role="Self-taught React developer"
            image="/images/avatar-ayoub.jpg"
            website="https://www.ayoubkanoun.com/"
            text={
              <>
                This course made me feel more <b>confident to start applying for jobs</b>. Before I started this course I didn't know <b>Agile methodology, Github branches, or pixel-perfect layouts</b>.<br /><br />
                It's great to get a code review after finishing a task. That helped me improve a lot. I surely recommend this course to anyone who wants to <b>know how real-world engineering teams work</b>.
              </>
            }
          />

          <h2>What happens after you enroll?</h2>
          <p>Once you enroll you'll receive an email with a signup link to the onboarding to this course.</p>
          <p>
            The onboarding is organized similarly to what you would experience when you start a new job as a software developer.
            You will be introduced to the project and techniques used in this course.
          </p>
          <p>
            Along the way you will
          </p>
          <ul>
            <li>learn how to break the designs into smaller tasks</li>
            <li>get an introduction to Agile development, Kanban, and modern project management</li>
            <li>get to know a professional Git workflow with pull requests, reviews, squash merging, and rebasing</li>
            <li>learn how to create pixel-perfect layouts</li>
            <li>and take your first steps writing automated tests.</li>
          </ul>
          <p>
            During the onboarding you will be guided through your first tasks. This way you can easily get started applying your new skills
            handson. Soon you'll take the training wheels off and implement the remaining tasks independently.
          </p>
          <p>
            Since the goal of this course is to gain experience working in a professional environment you will get access to a handful of
            software tools:
          </p>
          <ul>
            <li><b>ClickUp</b> as a project management tool</li>
            <li><b>Zeplin</b> as a design tool</li>
            <li>a <b>GitHub</b> repository for your code</li>
            <li>a <b>Slack</b> group for our communication</li>
          </ul>
          <p>
            Read the next sections for more information about the tools used in the course.
          </p>

          <Testimonial
            name="Javi Ortega"
            role="Freelance Drupal developer"
            image="/images/avatar-javi.jpg"
            website="https://rotegras.com"
            text={
              <>
                I found this course a great exercise not only to learn JavaScript and React but also to <b>correct some bad coding habits and get more consistent</b>.<br /><br />
                The effort Johannes puts in the code reviews shows the experience and knowledge he has to offer. I can only recommend it.
              </>
            }
          />

          <h2>Kanban and ClickUp</h2>
          <p>
            Project management is an integral part of any software team. There are many tools out there. We use ClickUp
            to learn Kanban as a modern agile workflow. It is intuitive and has a nice user interface.
          </p>
          <Image fluid={data.kanban.childImageSharp.fluid} />
          <p>
            All tasks are written in a professional way with user stories and detailed acceptance criteria.
          </p>
          <p>
            Many developers are not used to thinking of features in the scope of tasks at the beginning
            of their careers. They touch multiple features within one pull request.
          </p>
          <p>
            With well-defined tasks and code reviews, you learn a structured approach to feature-driven
            software development.
          </p>

          <h2>Pixel-perfect designs and Zeplin</h2>
          <p>
            One of the main tasks of every frontend developer is translating designs into functioning code.
            You get access to professional designs created by a UI-expert. They are made with Sketch and hosted on Zeplin.
          </p>
          <Image fluid={data.designs.childImageSharp.fluid} />
          <p>
            Creating pixel-perfect designs with CSS is another story. You learn relevant techniques that make you
            a valuable member of any design-focused team.
          </p>

          <h2>Pull requests and GitHub</h2>
          <p>
            You learn a professional workflow with GitHub, pull requests, and code reviews.
          </p>
          <p>
            With each pull request, you will get an automated review comment. This comment includes a lot of tips and advice
            for the specific task. You can follow the links in the checklist to get more detailed information.
          </p>
          <p>
            Each task also has an example implementation that you compare to your own solution.
          </p>
          <Image fluid={data.pr.childImageSharp.fluid} />
          <p>
            Most developers who lack professional team experience also lack practical knowledge of working with Git.
            But in a team setting, you can't work on the master branch anymore.
          </p>
          <p>
            You learn how to work with Git and pull requests. You will learn about and use branches, squash merging, and rebasing.
          </p>

          <h2>Automated Tests</h2>
          <p>
            Testing is an important skill for every team in a serious tech company. Many early-stage startups may not have a lot of
            tests in place. But with a growing product and user base, they are increasingly important.
          </p>
          <Image fluid={data.tests.childImageSharp.fluid} />
          <p>
            Since many junior developers never have written tests before this can be a decisive skill that lets you stand out
            when you apply for jobs.
          </p>
          <p>
            That's why you get the opportunity to cover the project in this course with tests. If you're not used to writing tests
            yet you will find beginner resources as well as tips and example implementations in the pull requests.
          </p>

          <h2>Team rules and ESLint</h2>
          <p>Every team has its own rules. And every developer needs to play by them.</p>
          <Image fluid={data.eslint.childImageSharp.fluid} />
          <p>We use ESLint rules and CI pipelines that help you format your code.</p>

          <h2>Slack community</h2>
          <p>
            You will get access to a private Slack group where you can ask questions and <b>communicate directly with me</b> as well as
            other developers.
          </p>
          <p>
            If you're stuck on a task we're there for you. You can always ask for help.
          </p>

          <Pricing />

          <h2>Frequently asked questions</h2>
          <h3>How much time do I need for this course?</h3>
          <p><b>The course will take around 2 weeks</b> depending on your experience and the time you can invest.</p>
          <p>
            You can work at your own pace to fit this course into your schedule. The project has a medium complexity.
            From my experience, it takes at least a week to implement it. You should be done within about 4 weeks if
            you can invest a few hours a couple of evenings a week.
          </p>

          <h3>Is it possible to take this course next to a full-time job?</h3>
          <p>
            Since you can work at your own pace you can also finish the course next to a full-time job. As a father of a
            small daughter I can understand that time can be scarce. It makes sense though to be able to focus a few hours
            a couple of days per week on the course. Maybe you can make it a habit and fit an hour or two in your daily routine.
          </p>

          <h3>Are my skills enough for this course?</h3>
          <p>
            Since we focus on software development in a professional environment <b>this course is not a good fit for
            complete beginners.</b>
          </p>
          <p>
            Maybe you have some experience with React already, but pull requests, Kanban, or working with designs
            are new to you. That's completely fine.
          </p>
          <p>
            But you should at least have a somewhat solid knowledge of JavaScript and React.
          </p>
          <p>
            You're probably on a good level for this course if you can implement a small app on your own
            like a todo or weather app with a bit of state management and data fetching.
          </p>
          <p>
            If you're in doubt whether your skills or available time are sufficient don't hesitate
            to <a href="mailto:course@ooloo.io">contact me</a>.
          </p>

          <h3>What if I realize the project is too difficult for me?</h3>
          <p>
            No worries. In case you think this course is too difficult for you at this point or you're not satisfied
            I offer a <b>30-day-money-back-guarantee</b>.
          </p>
        </Container>
      </Layout>
      <Footer />
    </>
  )
}

export default IndexPage
