import React from 'react'
import styled from "@emotion/styled"
import LinkedInIcon from '../icons/linkedin.svg'
import TwitterIcon from '../icons/twitter.svg'
import WebsiteIcon from '../icons/website.svg'

const PortraitWrapper = styled.div`
  margin: 0px 20px 5px;
  float: right;

  @media (min-width: 500px) {
    margin: 40px 30px 30px;
  }
`

const Image = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;

  @media (min-width: 500px) {
    width: 200px;
    height: 200px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 15px;

  @media (min-width: 500px) {
    padding: 0 52px;
  }
`

const Icon = styled.img`

`

function InstructorImage() {
  return (
    <PortraitWrapper>
      <Image src="/images/johannes-kettmann.jpeg" />
      <IconWrapper>
        <a href="https://jkettmann.com" target="_blank" rel="noopener noreferrer">
          <Icon src={WebsiteIcon} />
        </a>
        <a href="https://www.linkedin.com/in/johannes-kettmann-40a049145/" target="_blank" rel="noopener noreferrer">
          <Icon src={LinkedInIcon} />
        </a>
        <a href="https://twitter.com/j_kettmann" target="_blank" rel="noopener noreferrer">
          <Icon src={TwitterIcon} />
        </a>
      </IconWrapper>
    </PortraitWrapper>
  )
}

export default InstructorImage